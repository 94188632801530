import _esPromise from "../modules/es.promise.constructor";
import _esPromise2 from "../modules/es.promise.all";
import _esPromise3 from "../modules/es.promise.catch";
import _esPromise4 from "../modules/es.promise.race";
import _esPromise5 from "../modules/es.promise.reject";
import _esPromise6 from "../modules/es.promise.resolve";
// TODO: Remove this module from `core-js@4` since it's split to modules listed below
_esPromise;
_esPromise2;
_esPromise3;
_esPromise4;
_esPromise5;
_esPromise6;
export default {};