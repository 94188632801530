var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

var Queue = function () {
  (this || _global).head = null;
  (this || _global).tail = null;
};

Queue.prototype = {
  add: function (item) {
    var entry = {
      item: item,
      next: null
    };
    if ((this || _global).head) (this || _global).tail.next = entry;else (this || _global).head = entry;
    (this || _global).tail = entry;
  },
  get: function () {
    var entry = (this || _global).head;

    if (entry) {
      (this || _global).head = entry.next;
      if ((this || _global).tail === entry) (this || _global).tail = null;
      return entry.item;
    }
  }
};
exports = Queue;
export default exports;