import _global3 from "../internals/global";
import _promiseNativeConstructor from "../internals/promise-native-constructor";
import _isCallable from "../internals/is-callable";
import _isForced from "../internals/is-forced";
import _inspectSource from "../internals/inspect-source";
import _wellKnownSymbol from "../internals/well-known-symbol";
import _engineIsBrowser from "../internals/engine-is-browser";
import _engineIsDeno from "../internals/engine-is-deno";
import _isPure from "../internals/is-pure";
import _engineV8Version from "../internals/engine-v8-version";
var exports = {};
var _global2 = _global3;
var NativePromiseConstructor = _promiseNativeConstructor;
var isCallable = _isCallable;
var isForced = _isForced;
var inspectSource = _inspectSource;
var wellKnownSymbol = _wellKnownSymbol;
var IS_BROWSER = _engineIsBrowser;
var IS_DENO = _engineIsDeno;
var IS_PURE = _isPure;
var V8_VERSION = _engineV8Version;
var NativePromisePrototype = NativePromiseConstructor && NativePromiseConstructor.prototype;
var SPECIES = wellKnownSymbol("species");
var SUBCLASSING = false;
var NATIVE_PROMISE_REJECTION_EVENT = isCallable(_global2.PromiseRejectionEvent);
var FORCED_PROMISE_CONSTRUCTOR = isForced("Promise", function () {
  var PROMISE_CONSTRUCTOR_SOURCE = inspectSource(NativePromiseConstructor);
  var GLOBAL_CORE_JS_PROMISE = PROMISE_CONSTRUCTOR_SOURCE !== String(NativePromiseConstructor); // V8 6.6 (Node 10 and Chrome 66) have a bug with resolving custom thenables
  // https://bugs.chromium.org/p/chromium/issues/detail?id=830565
  // We can't detect it synchronously, so just check versions

  if (!GLOBAL_CORE_JS_PROMISE && V8_VERSION === 66) return true; // We need Promise#{ catch, finally } in the pure version for preventing prototype pollution

  if (IS_PURE && !(NativePromisePrototype["catch"] && NativePromisePrototype["finally"])) return true; // We can't use @@species feature detection in V8 since it causes
  // deoptimization and performance degradation
  // https://github.com/zloirock/core-js/issues/679

  if (!V8_VERSION || V8_VERSION < 51 || !/native code/.test(PROMISE_CONSTRUCTOR_SOURCE)) {
    // Detect correctness of subclassing with @@species support
    var promise = new NativePromiseConstructor(function (resolve) {
      resolve(1);
    });

    var FakePromise = function (exec) {
      exec(function () {
        /* empty */
      }, function () {
        /* empty */
      });
    };

    var constructor = promise.constructor = {};
    constructor[SPECIES] = FakePromise;
    SUBCLASSING = promise.then(function () {
      /* empty */
    }) instanceof FakePromise;
    if (!SUBCLASSING) return true; // Unhandled rejections tracking support, NodeJS Promise without it fails @@species test
  }

  return !GLOBAL_CORE_JS_PROMISE && (IS_BROWSER || IS_DENO) && !NATIVE_PROMISE_REJECTION_EVENT;
});
exports = {
  CONSTRUCTOR: FORCED_PROMISE_CONSTRUCTOR,
  REJECTION_EVENT: NATIVE_PROMISE_REJECTION_EVENT,
  SUBCLASSING: SUBCLASSING
};
export default exports;