import _promiseNativeConstructor from "../internals/promise-native-constructor";
import _checkCorrectnessOfIteration from "../internals/check-correctness-of-iteration";
import _promiseConstructorDetection from "../internals/promise-constructor-detection";
var exports = {};
var NativePromiseConstructor = _promiseNativeConstructor;
var checkCorrectnessOfIteration = _checkCorrectnessOfIteration;
var FORCED_PROMISE_CONSTRUCTOR = _promiseConstructorDetection.CONSTRUCTOR;
exports = FORCED_PROMISE_CONSTRUCTOR || !checkCorrectnessOfIteration(function (iterable) {
  NativePromiseConstructor.all(iterable).then(undefined, function () {
    /* empty */
  });
});
export default exports;